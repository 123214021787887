import React, { useEffect, useState } from "react";
import NavBar from "../NavBar";
import Footer from "../subcomponents/footer";
import "./WhyInvest.css";
import CardSlider from "../subcomponents/EventScrollereventpage";
import Slider from "react-slick";
import { useLocation } from "react-router-dom";
import axios from "axios";
import pak from "../../../src/assests/images/pak.png";
import FSP from "../../assests/Policies Thumbnail/federal/National-Food-Security-Policy.jpg";
import PAP from "../../assests/Policies Thumbnail/punjab/Punjab-Agriculture-Policy.jpg";
import BAP from "../../assests/Policies Thumbnail/balochistan/Balochistan-Agriculture-Policy copy.jpg";
import KAP from "../../assests/Policies Thumbnail/kpk/KP-Agriculture-Policy.jpg";
import SAP from "../../assests/Policies Thumbnail/sindh/Sindh-Agriculture-Policy copy.jpg";
import linkIcon from "../../assests/Icons/link.svg";
import NSP from "../../assests/Policies Thumbnail/federal/IT.5 copy.jpg";
import FrameworkforTelecomInfrastructureSharing from "../../assests/Policies Thumbnail/federal/IT.6 copy.jpg";
import IT3 from "../../assests/Policies Thumbnail/federal/IT.7 copy.jpg";
import IT4 from "../../assests/Policies Thumbnail/federal/IT.8 copy.jpg";
import IT5 from "../../assests/Policies Thumbnail/federal/IT.9 copy.jpg";
import CS from "../../assests/Policies Thumbnail/federal/IT.1.jpg";
import PCP from "../../assests/Policies Thumbnail/federal/IT.2.jpg";
import PDP from "../../assests/Policies Thumbnail/federal/IT.3 copy.jpg";
import BP from "../../assests/Policies Thumbnail/federal/IT.11 copy.jpg";
import AIP from "../../assests/Policies Thumbnail/federal/IT.4 copy.jpg";
import CDLT from "../../assests/Policies Thumbnail/federal/IT.4.1 copy.jpg";
import DGP from "../../assests/Policies Thumbnail/federal/IT.4.2 copy.jpg";

import SS1 from "../../assests/Policies Thumbnail/sindh/Sindh-Mines-and-Minerals-Governance-Act.jpg";
import SS2 from "../../assests/Policies Thumbnail/sindh/Sindh-Mines-and-Minerals-Governance-Rules copy.jpg";
import BMP1 from "../../assests/Policies Thumbnail/balochistan/Balochistan-Mines-&-Mineral-Development-Policy copy.jpg";
import NMP1 from "../../assests/Policies Thumbnail/federal/National-Mineral-Policy.jpg";

import POR from "../../assests/Policies Thumbnail/federal/Establishment-of-Greenfield-Refineries.png";
import POR2 from "../../assests/Policies Thumbnail/federal/Upgradation-of-Brownfield-Existing-Refineries.png";

import evp from "../../assests/Policies Thumbnail/federal/Auto-Industry-Development-And-Export-Policy-(AIDEP).jpg";
import ADP from "../../assests/Policies Thumbnail/federal/Automotive-Development-Policy,.jpg";
import EVP from "../../assests/Policies Thumbnail/federal/Electric-Vehicle-Policy,-2020-2025.jpg";
import Private from "../../assests/Policies Thumbnail/federal/Privatization-Policy.jpg";
import TSP from "../../assests/Policies Thumbnail/federal/Tourism-Sector-Profile.jpg";
import MDP from "../../assests/Policies Thumbnail/federal/Mobile-Device-Manufacturing-Policy.jpg";

import IP2023 from "../../assests/Policies Thumbnail/general policy/Investment policy.jpg";
import PRMI from "../../assests/Policies Thumbnail/general policy/PRMI.jpg";
import PIG2023 from "../../assests/Policies Thumbnail/general policy/Pakistan Investment Guide.jpg";
import RVP2023 from "../../assests/Policies Thumbnail/general policy/Revised Visa Policy.jpg";

import RA1 from "../../assests/Regulations/Agriculture Reg/(FSC&RD Federal Seed Certification and Registration Department (FSC&RD).jpg";
import RA2 from "../../assests/Regulations/Agriculture Reg/Plant BreedersΓÇÖ Rights Act.jpg";
import RA3 from "../../assests/Regulations/Agriculture Reg/Plant BreedersΓÇÖ Rights Rules.jpg";
import RA4 from "../../assests/Regulations/Agriculture Reg/Provincial level regulatory mechanisms.jpg";
import RA5 from "../../assests/Regulations/Agriculture Reg/Seed (Business Regulation) Rules.jpg";
import RA6 from "../../assests/Regulations/Agriculture Reg/Seed (Registration) Rules.jpg";
import RA7 from "../../assests/Regulations/Agriculture Reg/Seed Act-1976.jpg";
import RA8 from "../../assests/Regulations/Agriculture Reg/Seed Amendment Act.jpg";

import mineralKp from "../../assests/Policies Thumbnail/kpk/kp mineral dev policy.jpg";

import RM1 from "../../assests/Regulations/Mines & Minerals/Concession Rules.jpg";
import RM2 from "../../assests/Regulations/Mines & Minerals/Institutional Framework.jpg";
import RM3 from "../../assests/Regulations/Mines & Minerals/Provincial Legislation.jpg";
import RM4 from "../../assests/Regulations/Mines & Minerals/Regulatory Regime.jpg";

import RT1 from "../../assests/Regulations/Industry, Tourism & Privatisation/AJ&K.jpg";
import RT2 from "../../assests/Regulations/Industry, Tourism & Privatisation/Balochistan.jpg";
import RT3 from "../../assests/Regulations/Industry, Tourism & Privatisation/Gilgit Baltistan.jpg";
import RT4 from "../../assests/Regulations/Industry, Tourism & Privatisation/Khyber Pakhtunkhwa.jpg";
import RT5 from "../../assests/Regulations/Industry, Tourism & Privatisation/Punjab.jpg";
import RT6 from "../../assests/Regulations/Industry, Tourism & Privatisation/Sindh.jpg";

import RIT6 from "../../assests/Regulations/IT reg/Pakistan Telecommunication Authority.jpg";

import RE from "../../assests/Regulations/Energy/Global Export Leaders.jpg";
import RE1 from "../../assests/Regulations/Energy/Lucrative GCC Demand.jpg";
import RE2 from "../../assests/Regulations/Energy/Pakistan's Dairy Prowess.jpg";
import RE3 from "../../assests/Regulations/Energy/Regulatory Authority.jpg";

import ic1 from "../../assests/ICBanner/Frame 7989.png";
import ic2 from "../../assests/ICBanner/Frame 7991.png";
import ic3 from "../../assests/ICBanner/Frame 7992.png";
import ic5 from "../../assests/ICBanner/Frame 7993.png";
import ic6 from "../../assests/ICBanner/Frame 7994.png";
import ic7 from "../../assests/ICBanner/Frame 7995.png";
import ic20 from "../../assests/ICBanner/Frame 7996.png";
import ic19 from "../../assests/ICBanner/Frame 7999.png";
import ic8 from "../../assests/ICBanner/Frame 7997.png";
import ic18 from "../../assests/ICBanner/Frame 7998.png";
import ic11 from "../../assests/ICBanner/Frame 7999.png";
import ic12 from "../../assests/ICBanner/Frame 8001.png";
import ic13 from "../../assests/ICBanner/Frame 8002.png";
import ic14 from "../../assests/ICBanner/Frame 8003.png";
import ic15 from "../../assests/ICBanner/Frame 8004.png";
import ic16 from "../../assests/ICBanner/Frame 8005.png";
import ic17 from "../../assests/ICBanner/Frame 8000.png";
import ic23 from "../../assests/images/Aquaculture.png";
import ic24 from "../../assests/images/Cannabis Control.png";
import ic25 from "../../assests/images/National Seed Policy.png";
import ic21 from "../../assests/ICBanner/Frame 7990.png";
import ic22 from "../../assests/ICBanner/Frame 7335.png";
import ic9 from "../../assests/ICBanner/1.png";
// import ic10 from "../../assests/ICBanner/2.png";
import ic4 from "../../assests/ICBanner/3.png";
import ic26 from "../../assests/ICBanner/4.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const apiUrl = process.env.REACT_APP_API_URL;
const myURLForImages = process.env.REACT_APP_STAGGING_URL_FOR_Images;
export default function InvestmentClimate() {
  const [scrolling, setScrolling] = useState(false);
  const [paramID, setparamID] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [paramTab, setparamTab] = useState(0);

  const location = useLocation();
  useEffect(() => {
    // Parse query parameters from location.search
    const queryParams = new URLSearchParams(location.search);

    // Access specific query parameter values
    const param1Value = queryParams.get("sector");
    const param2Value = queryParams.get("section");

    // Log or use the values as needed
    // console.log("param1:", param1Value);
    // console.log("param2:", param2Value);
    setparamID(param1Value || 0);
    setparamTab(param2Value || 0);
  }, [location.search]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const cards = [
    {
      image: ic9,
    },
    {
      image: ic4,
    },
    {
      image: ic26,
    },
    {
      image: ic17,
    },
    {
      image: ic22,
    },
    {
      image: ic21,
    },
    {
      image: ic1,
    },
    {
      image: ic18,
    },
    {
      image: ic19,
    },
    {
      image: ic20,
    },
    {
      image: ic2,
    },
    {
      image: ic3,
    },

    {
      image: ic5,
    },
    {
      image: ic6,
    },
    // {
    // image: ic7,
    // },
    {
      image: ic8,
    },

    {
      image: ic11,
    },
    {
      image: ic12,
    },
    {
      image: ic13,
    },
    {
      image: ic14,
    },
    {
      image: ic15,
    },
    {
      image: ic16,
    },
    {
      image: ic23,
    },
    {
      image: ic24,
    },
    {
      image: ic25,
    },
  ];

  const projects = [
    {
      id: 0,
      name: "General Policies",
      content: {
        tabs: [
          {
            title: "General Policies & Regulations",
            list: [
              {
                heading: "",
                text: "",
                image: IP2023,
                link: "https://invest.gov.pk/sites/default/files/inline-files/Final%20PIP%202023-cs.pdf?gtranslate=en#:~:text=8.1%20The%20overarching%20strategy%20of,%2Dvalue%20jobs%2C%20iii)%20extend",
              },
              {
                heading: "",
                text: "",
                image: PIG2023,
                link: "https://invest.gov.pk/investment-regime?language_id=en#gallery-1",
              },
              {
                heading: "",
                text: "",
                image: RVP2023,

                link: "Be downloaded from MoI Website",
              },
              {
                heading: "",
                text: "",
                image: PRMI,

                link: "https://piu.punjab.gov.pk/node/137",
              },
            ],
          },
          // {
          //   title: "Sectoral Policies",
          //   list: [
          //     {
          //       heading: "Strategic Location:",
          //       text: "Prime 50,000 acres of culturable Cholistan wasteland.",
          //       link: "",
          //     },
          //     {
          //       heading: "Investment Potential:",
          //       text: "Front-loaded Rs. 37.55 billion showcasing feasibility and profitability.",
          //       link: "",
          //     },
          //     {
          //       heading: "Cutting-Edge Techniques:",
          //       text: "Utilize advanced farming technologies for optimal yields: 60, 40, 30, and 45 maunds/acre for wheat, canola, millet, and cotton, respectively.",
          //       link: "",
          //     },
          //     {
          //       heading: "Impressive Yields:",
          //       text: "At full development, project yields 14,400 mt of wheat, 6,400 mt of canola, 2,400 mt of millet, and 14,400 mt of cotton annually.",
          //       link: "",
          //     },
          //     {
          //       heading: "Diversification:",
          //       text: "Explore cultivation of diverse fruits, vegetables, and legumes in Cholistan’s agro-climatic conditions.",
          //       link: "",
          //     },
          //   ],
          // },
        ],
      },
    },
    {
      id: 1,
      name: "Agriculture & Livestock",
      content: {
        heading: "",
        tagline:
          "Unlock unparalleled investment potential in the heart of Cholistan with our transformative project in corporate farming spanning 50,000 acres of culturable wasteland.",
        tabs: [
          {
            title: "Policies",
            list: [
              {
                heading: "",
                text: "",
                image: FSP,
                link: "https://pbit.punjab.gov.pk/system/files/National%20Food%20Security%20Policy%202018.pdf",
              },
              {
                heading: "",
                text: "",
                image: PAP,
                link: "https://piu.punjab.gov.pk/system/files/Agriculture%20Policy%20of%20Punjab%202017%20%28Final%29.pdf",
              },
              {
                heading: "",
                text: "",
                image: BAP,
                link: "https://faolex.fao.org/docs/pdf/pak173418.pdf",
              },
              {
                heading: "",
                text: "",
                image: KAP,
                link: "https://cms.sifc.gov.pk/images/KP_Ag_Policy.pdf",
              },
              {
                heading: "",
                text: "",
                image: SAP,
                link: "https://faolex.fao.org/docs/pdf/pak191432.pdf",
              },
            ],
          },
          {
            title: "Incentives",
            list: [
              {
                heading: "",
                InnerList: [
                  {
                    heading: "Equity Ownership",
                    text: "Secure a significant 60% stake in agriculture projects as a foreign investor",
                    link: "",
                  },
                  {
                    heading: "Corporate Agriculture Farming (CAF)",
                    text: "Enjoy full control with 100% equity ownership in Corporate Agriculture Farming",
                    link: "",
                  },
                  {
                    heading: "Streamlined Approvals",
                    text: "Obtain specific licenses for efficient project initiation, eliminating the need for extensive government permissions",
                    link: "",
                  },
                  {
                    heading: "Capital Remittance",
                    text: "Seamlessly remit capital, profits, and dividends for a hassle-free financial environment",
                    link: "",
                  },
                  {
                    heading: "Full Foreign Equity",
                    text: "Maximize involvement with a 100% cap on foreign equity",
                    link: "",
                  },
                  {
                    heading: "Customs Duty Relief",
                    text: "Benefit from a 0% tariff on Plant and Machinery Equipment (PME) imports",
                    link: "",
                  },
                  {
                    heading: "Tax Incentives",
                    text: "Enjoy a 25% reduction on Investment Tax Credit (IDA) as a percentage of PME cost",
                    link: "",
                  },
                  {
                    heading: "Royalty and Technical Fee",
                    text: "Leverage guidelines allowing royalty and technical fee payments, including an initial lump sum and a maximum rate of 5% of net sales for the first 5 years                      ",
                    link: "",
                  },
                ],
              },
            ],
          },
          {
            title: "Regulations",
            list: [
              {
                heading: "",
                InnerList: [
                  {
                    heading:
                      "Federal Seed Certification and Registration Department (FSC&RD)",
                    text: "It oversees various activities with the goal of providing technical expertise and maintaining the quality of registered seed varieties.",
                    link: "http://www.federalseed.gov.pk/index",
                  },
                  {
                    heading: "Seed Act, 1976",
                    text: "The provincial governments have collectively enacted legislation for controlling and regulating the quality of seeds of various varieties of crops. ",

                    link: "http://www.federalseed.gov.pk/SiteImage/Misc/files/Seed%20Act%201976.pdf",
                  },
                  {
                    heading: "Seed Amendment Act, 2015",
                    text: "This amendment has been introduced with a specific focus on enhancing and refining seed quality standards across various crops to foster a favorable environment for the agricultural sector.",
                    link: "http://www.federalseed.gov.pk/SiteImage/Misc/files/The%20Seed%20(Amendment)%20Act%202015%20.pdf",
                  },
                  {
                    heading: "Seed (Business Regulation) Rules, 2016",
                    text: "The provincial governments have passed a resolution to foster innovation in agriculture and safeguard the rights of breeders of new plant varieties.",
                    link: "http://www.federalseed.gov.pk/SiteImage/Misc/files/Seed%20(Business%20Regulation)%20Rules%2C%202016.pdf",
                  },
                  {
                    heading: "Plant Breeders’ Rights Act, 2018",
                    text: "The federal government has formulated rules to further promote the cultivation of innovative plant varieties and secure the rights of breeders involved in their development.",
                    link: "http://www.federalseed.gov.pk/SiteImage/Misc/files/PLANT%20BREEDERS%20RIGHTS%20ACT%20%202016.pdf",
                  },

                  {
                    heading: "Seed (Registration) Rules, 1987",
                    text: "The federal government has created regulations to regulate activities linked to seeds with the goal of encouraging uniformity and quality control in the seed sector.",
                    link: "http://www.federalseed.gov.pk/SiteImage/Misc/files/SeedRegistrationRules2015.pdf",
                  },
                ],
              },
            ],
          },
          // ... (other tabs)
        ],
      },
    },
    {
      id: 2,
      name: "IT  & Telecom",
      content: {
        heading:
          "Establishment of Corporate Feedlot Farm 100,000 Animals (10,000 Acres)",
        tagline:
          "Embark on a transformative journey with the establishment of a state-of-the-art corporate feedlot farm, housing 100,000 animals across a sprawling 10,000 acres.",
        tabs: [
          {
            title: "Policies",
            list: [
              {
                heading: "",
                text: "",
                link: "  https://suparco.gov.pk/wp-content/uploads/2024/01/National-Space-Policy.pdf",
                image: NSP,
              },
              {
                heading: "",
                text: "",
                link: "https://www.pta.gov.pk/assets/media/fg_approved_telecom_infrastructure_sharing_framework_16-11-2023.pdf",
                image: FrameworkforTelecomInfrastructureSharing,
              },

              {
                heading: "",
                text: " ",
                link: "https://moitt.gov.pk/SiteImage/Misc/files/Pakistan%20Cloud%20First%20Policy-Final-25-02-2022.pdf",
                image: PCP,
              },
              ,
              {
                heading: "",
                text: " ",
                link: "https://moitt.gov.pk/SiteImage/Misc/files/Final%20Draft%20Personal%20Data%20Protection%20Bill%20May%202023.pdf",
                image: PDP,
              },
              ,
              {
                heading: "",
                text: " ",
                link: "https://www.usf.org.pk/storage/rules-policies/1635420728-broadband-policypdf.pdf",
                image: BP,
              },
              ,
              {
                heading: "",
                text: " ",

                link: "https://moitt.gov.pk/SiteImage/Misc/files/National%20AI%20Policy%20Consultation%20Draft%20V1.pdf",
                image: AIP,
              },
              ,
              {
                heading: "",
                text: " ",
                image: DGP,
                link: "",
              },

              {
                heading: "",
                text: " ",
                image: CS,
                link: "https://moitt.gov.pk/SiteImage/Misc/files/National%20Cyber%20Security%20Policy%202021%20Final.pdf ",
              },
              {
                heading: "",
                text: " ",
                image: IT5,
                link: "https://moitt.gov.pk/SiteImage/Misc/files/POLICY%20DIRECTIVE%20FOR%20THE%20AUCTION%20OF%20%20NGMS%20SPECTRUM%20IN%20PAKISTAN%20Dated%204%20A___(1).pdf",
              },
              {
                heading: "",
                text: " ",
                image: IT3,
                link: "https://www.pta.gov.pk/category/policy-directive-under-section-82-of-the-pakistan-telecommunications-re-organization-act-1996-for-renewal-of-cellular-mobile-licenses-in-2019-938713429-2023-06-01",
              },
              {
                heading: "",
                text: " ",
                image: IT4,
                link: "https://www.pta.gov.pk/assets/media/final_framework_5g_test_trials_260619.pdf",
              },
            ],
          },
          {
            title: "Incentives",
            list: [
              {
                heading: "",
                InnerList: [
                  {
                    heading: "Tax Exemption",
                    text: "Venture capital companies and funds enjoy tax exemption on profits and gains till June 30, 2025",
                    link: "",
                  },
                  {
                    heading: "Foreign Ownership",
                    text: "Embrace opportunities with up to 100% foreign ownership",
                    link: "",
                  },
                  {
                    heading: "Profit Repatriation",
                    text: "Benefit from 100% profit repatriation",
                    link: "",
                  },
                  {
                    heading: "Reduced Income Tax (0.25%)",
                    text: "PSEB registered businesses and freelancers pay only 0.25% tax on export proceeds of IT & IT-Enabled Services (till June 2026)",
                    link: "",
                  },
                  {
                    heading: "100% Tax Credit for IT Startups",
                    text: "PSEB registered Startups offering technology-driven products or services to any sector of the economy are allowed 100% tax credit, including minimum, alternate corporate tax, and final taxes for three years",
                    link: "",
                  },
                  {
                    heading: "50% Foreign Currency Retention",
                    text: "Exporters of Software, Information Technology (IT), and IT-enabled Services (ITeS), as well as freelance services, are permitted to retain up to 50% of export remittances in Exporters’ Special Foreign Currency Account (ESFCA) for meeting business-related international expenses, including travel abroad",
                    link: "",
                  },
                ],
              },
              {
                heading:
                  "Special Technology Zones Authority (STZA) Incentives for Enterprises & Zone Developers for 10 years",
                InnerList: [
                  {
                    heading: "Tax Exemptions",
                    text: "Tech enterprises and zone developers enjoy a 10-year tax exemption under the Income Tax Ordinance, 2001",
                    link: "",
                  },
                  {
                    heading: "Import Tax Relief",
                    text: "Capital goods imports are exempt from taxes",
                    link: "",
                  },
                  {
                    heading: "Income Tax Benefits",
                    text: "Exemption from income tax on dividend income and capital gains",
                    link: "",
                  },
                  {
                    heading: "Property Tax Exemption",
                    text: "No property tax obligations",
                    link: "",
                  },
                  {
                    heading: "Sales Tax Waiver",
                    text: "Full exemption from sales tax under the Sales Tax Act, 1990",
                    link: "",
                  },
                  {
                    heading: "Repatriation Freedom",
                    text: "Investments, profits, and dividends are fully repatriable, with overseas payments permitted without constraints",
                    link: "",
                  },
                  {
                    heading: "Special Foreign Currency Account",
                    text: "Benefit from a designated account facilitating foreign currency transactions, providing ease and flexibility in managing international financial activities",
                    link: "",
                  },
                ],
              },
            ],
          },
          {
            title: "Regulations",
            list: [
              {
                heading: "",
                InnerList: [
                  {
                    heading: "Pakistan Telecommunication Authority (PTA)",
                    text: "Pakistan Telecommunication Authority (PTA) is an independent body to regulate the establishment, operation and maintenance of telecommunication systems and provision of telecommunication services in Pakistan",
                    link: "https://www.pta.gov.pk/en",
                  },
                ],
              },
              {
                heading: "Fintech Regulation                ",
                InnerList: [
                  {
                    heading: "State Bank of Pakistan (SBP)",
                    text: "The State Bank of Pakistan (SBP) is the primary regulator of Fintech in Pakistan. It formulates policies, guidelines, and regulatory frameworks for the financial sector's smooth functioning, including fintech.                     ",
                    link: "",
                  },
                  {
                    heading:
                      "   Securities and Exchange Commission of Pakistan (SECP)",
                    text: "The Securities and Exchange Commission of Pakistan (SECP) may also have regulatory oversight for fintech companies involved in crowdfunding, peer-to-peer lending, or securities-related activities.",
                    link: "",
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      id: 3,
      name: "Mines & Minerals",
      content: {
        tabs: [
          {
            title: "Policies",
            list: [
              {
                image: SS1,
                heading: "",
                text: "",
                link: "https://smd.gov.pk/LinkClick.aspx?fileticket=AG-CS2t2N1o%3d&tabid=41&mid=421",
              },
              {
                heading: "",
                image: SS2,
                text: "",
                link: "https://smd.gov.pk/LinkClick.aspx?fileticket=dyvova1gwyY%3d&tabid=41&mid=421",
              },
              {
                heading: "",
                image: BMP1,
                text: "",
                link: "https://cms.sifc.gov.pk/images/Balochistan%20Mineral.pdf",
              },
              {
                heading: "",
                image: NMP1,
                text: "",
                link: "https://pbit.punjab.gov.pk/system/files/National%20Mineral%20Policy%2C%202013.pdf",
              },
              {
                heading: "",
                image: mineralKp,
                text: "",
                link: "https://kpminerals.gov.pk/uploads/rules_and_policies/attachment/1687159976_Final%20Book%20for%20Printing%20CDR%20-%20Curve.pdf",
              },
            ],
          },
          {
            title: "Incentives",
            list: [
              {
                heading: "",
                InnerList: [
                  {
                    heading: "Customs Duty & Sales Tax Relief",
                    text: "Enjoy concessional rates (0% for Exploration, 3%, 5% for Development) on the import of machinery, fostering cost-effective operations",
                    link: "",
                  },
                  ,
                  {
                    heading: "Foreign Equity",
                    text: "Benefit from the flexibility of up to 100% foreign equity, offering unparalleled control",
                    link: "",
                  },
                  {
                    heading: "Expatriate Facilitation",
                    text: "Seamlessly navigate expatriate processes, ensuring a smooth operational environment",
                    link: "",
                  },
                  {
                    heading: "Export Processing Zone Incentives",
                    text: "Leverage incentives based on project economics and export potential, enhancing project viability",
                    link: "",
                  },
                  {
                    heading: "China-Pakistan Economic Corridor Inclusion",
                    text: "Explore opportunities for inclusion, offering strategic advantages on a case-to-case basis",
                    link: "",
                  },
                  {
                    heading: "Protection from Expropriation",
                    text: "Safeguard investments with protection against expropriation risks",
                    link: "",
                  },
                  {
                    heading: "Repatriation of Capital & Profits",
                    text: "Ensure financial flexibility with the freedom to repatriate capital and profits",
                    link: "",
                  },
                ],
              },
              {
                heading: "Additional Incentives",
                InnerList: [
                  {
                    heading:
                      "A.	Foreign Private Investment (Promotion and Protection) Act, 2022",
                    text: "Protect investor rights, adjudicate tax concessions, and provide Export Processing Zone incentives, fostering an investor-friendly environment",
                    link: "",
                  },

                  {
                    heading: "B.	Fiscal Incentives for Thar Coalfield",
                    text: "Special Economic Zone status for Thar Coalfield",
                    link: "",
                  },
                  {
                    heading: "",
                    text: "Zero percent customs duties on machinery imports",
                    link: "",
                  },
                  {
                    heading: "",
                    text: "30-year exemption on withholding tax on dividends",
                    link: "",
                  },
                  {
                    heading: "",
                    text: "Exemption on withholding tax for procurement",
                    link: "",
                  },
                  {
                    heading: "",
                    text: "30-year exemption on various levies",
                    link: "",
                  },
                  {
                    heading: "",
                    text: "Sales tax exemption on coal-mining machinery import",
                    link: "",
                  },
                  {
                    heading: "",
                    text: "Input tax adjustment for power units using Thar coal",
                    link: "",
                  },
                  {
                    heading: "C.	General (Income Tax Ordinance, 2001)",
                    text: "Stimulate employment with a 2% tax credit for every 50 employees, reinforcing commitment to growth",
                    link: "",
                  },
                ],
              },
            ],
          },
          {
            title: "Regulations",
            list: [
              {
                heading: "Mineral Framework",
                InnerList: [
                  {
                    heading: "Constitutional Framework",
                    text: "In line with the Constitution of Pakistan, minerals' exploration and development, excluding oil, gas, and nuclear substances, fall under the respective Province's regulatory authority",
                    link: "",
                  },
                  {
                    heading: "Federal Guidance  ",
                    text: "The Federal Government takes charge of geological surveys, coordinating policies, and formulating plans for national and international mineral sector development",
                    link: "",
                  },
                  {
                    heading: "Resource Collaboration                    ",
                    text: "Federal organizations actively contribute to mineral resource exploration and development",
                    link: "",
                  },
                ],
              },

              {
                heading: "Regulatory Regime",
                InnerList: [
                  {
                    heading: "Provincial Legislation",
                    text: "Each Province has enacted its laws, rules, and regulations governing mining concessions and occupational health aspects. These rules delineate procedures, working principles, and the responsibilities of departmental authorities, along with the obligations of mining concessionaires",
                    link: "",
                  },

                  {
                    heading: "Concession Rules",
                    text: "These rules facilitate various mineral titles to be granted and renewed, ensuring sufficient time for exploration, development, and exploitation",
                    link: "",
                  },
                ],
              },

              {
                heading: "Mineral Titles & Extractive Contracts Overview",
                InnerList: [
                  {
                    heading: "",
                    text: "",
                    link: "",
                    subHeading:
                      "Reconnaissance/Prospecting/Exploration License",
                  },
                  {
                    heading: " Mode of Grant",
                    text: "Based on the principle of first come first served, subject to approved technical and financial criteria",
                    link: "",
                  },
                  {
                    heading: " Max. Tenure",
                    text: "RL-01 years, PL-5 years, EL-9 years",
                    link: "",
                  },
                  {
                    heading: " Investment",
                    text: "Licensee incurs all expenditures on prospecting and exploration works, infrastructure, and machinery deployment",
                    link: "",
                  },
                  {
                    heading: "",
                    text: "",
                    link: "",
                    subHeading: "Mining Lease",
                  },
                  {
                    heading: "Mode of Grant",
                    text: "Auction in areas with proved minerals",
                    link: "",
                  },
                  {
                    heading: "",
                    text: "Conversion of prospecting/exploration licenses",
                    link: "",
                  },
                  {
                    heading: "Max. Tenure",
                    text: "30 years, renewable",
                    link: "",
                  },
                  {
                    heading: "Investment",
                    text: "Lessee incurs all expenditures on development works, including mine design, infrastructure provision, installation of mines and mineral processing plants, and procurement of necessary machinery",
                    link: "",
                  },
                ],
              },
            ],
          },
          // ... (other tabs)
        ],
      },
    },
    {
      id: 4,
      name: "Energy (Petroleum & Power)",
      content: {
        heading:
          "Establishment of Corporate Dairy Farm 30,000 Holstein Friesian Animals (15,000 Acres)",
        tagline:
          "Embark on a transformative venture with the establishment of a cutting-edge corporate dairy farm, housing 30,000 Holstein Friesian animals across an expansive 15,000-acre domain.",
        tabs: [
          {
            title: "Policies",
            list: [
              {
                heading: "",
                text: "",
                link: "https://petroleum.gov.pk/SiteImage/Downloads/Brownfield%20Refinery%20Policy-2023.pdf",
                image: POR2,
              },
              {
                heading: "",
                text: "",
                link: "https://petroleum.gov.pk/SiteImage/Downloads/Pakistan%20Oil%20Refining%20Policy%202023%20For%20New%20Greenfield%20Refineries%20160523.pdf",
                image: POR,
              },
            ],
          },
          {
            title: "Incentives",
            list: [
              {
                heading: "",
                InnerList: [
                  {
                    heading: "Decade-Long Impact",
                    text: "Envisage a transformative decade where the project becomes a catalyst for economic activity, generating sustained employment for over 10,417 individuals during the construction phase and throughout its operational life.",
                    link: "",
                  },
                  {
                    heading: "Tax Revenue Generation",
                    text: "Contribute significantly to provincial and federal tax revenues, aligning the project with national economic goals while fostering financial stability.",
                    link: "",
                  },
                  {
                    heading: "Tax Exemptions",
                    text: "Enjoy relief from Income Tax, Turnover Tax, and Sales Tax (depending on project type and technology)",
                    link: "",
                  },

                  {
                    heading: "Import Concessions",
                    text: "Benefit from concessions on Project Plant & Machinery imports, streamlining project setup",
                    link: "",
                  },

                  {
                    heading: "Corporate Structure",
                    text: "Exercise full control with 100% Foreign Ownership, fostering a favorable investment environment",
                    link: "",
                  },

                  {
                    heading: "GOP Guarantees",
                    text: "Ensure peace of mind with Government of Pakistan guarantees for payment obligations in Power & Transmission Services",
                    link: "",
                  },

                  {
                    heading: "Protection",
                    text: "Safeguard investments with provisions for Force Majeure and Change in Law scenarios",
                    link: "",
                  },

                  {
                    heading: "Hydrological Risk",
                    text: "Mitigate risk as Hydrological Risk falls on the Power Purchaser",
                    link: "",
                  },

                  {
                    heading: "Attractive Return on Equity",
                    text: "Experience an attractive ROE indexed for USD/PKR variation determined by NEPRA",
                    link: "",
                  },

                  {
                    heading: "Tariff Indexation",
                    text: "Benefit from tariff indexation tied to inflation (US CPI & Pak WPI) and foreign costs USD/PKR parity",
                    link: "",
                  },

                  {
                    heading: "FX Regime",
                    text: "Government of Pakistan assures the conversion of PKR to USD and the remittance of foreign exchange",
                    link: "",
                  },

                  {
                    heading: "PPA/TSA Term",
                    text: "Choose flexibility with Power Purchase Agreement/Transmission Services Agreement terms ranging from 25 to 30 years, depending on the project ",
                    link: "",
                  },
                  {
                    heading: "Dispute Resolution",
                    text: "Enjoy a streamlined three-tier dispute resolution mechanism, including mutual discussions, determination by experts, and arbitration under LCIA/UNCITRAL (London Court of International Arbitration/United Nations Commission on International Trade Law) rules",
                    link: "",
                  },
                  {
                    heading: "Governing Law",
                    text: "Navigate with clarity using Pakistani Law for Implementation Agreement & Power Purchase Agreement/Transmission Services Agreement and English Law for Dispute Resolution Involving Foreign Lenders",
                    link: "",
                  },
                ],
              },

              {
                heading: "Incentives under Oil Refining Policy 2023",
                InnerList: [
                  {
                    heading: "Income Tax Exemption",
                    text: "New deep conversion oil refinery projects enjoy income tax exemption for 20 years (for a minimum 300,000 bpd refinery) and 10 years (for a minimum 100,000 bpd refinery) from the date of commercial production commencement",
                    link: "",
                  },
                  {
                    heading: "Custom Duty Exemption",
                    text: "Benefit from exemption on custom duties, withholding income tax, and sales taxes on imported plant and machinery for project construction and execution",
                    link: "",
                  },

                  {
                    heading: "Tariff Protection",
                    text: "Enjoy tariff protection with a minimum custom duty of 7.5% on imported Motor Spirit and High-Speed Diesel for 25 years (300,000 bpd refineries) and 10 years (100,000 bpd refineries) to generate revenue for the refineries",
                    link: "",
                  },

                  {
                    heading: "Crude Oil Import Freedom",
                    text: "Refineries have the freedom to import crude oil from any source",
                    link: "",
                  },

                  {
                    heading: "Surplus Petroleum Product Export",
                    text: "Refineries can export surplus petroleum products without domestic demand, with intimation to Oil & Gas Regulatory Authority (OGRA) and Ministry of Energy (Petroleum Division) (MEPD)",
                    link: "",
                  },
                ],
              },
            ],
          },
          {
            title: "Regulations",
            list: [
              {
                heading: " Power Sector",
                InnerList: [
                  {
                    heading: "Regulatory Authority",
                    text: "National Electric Power Regulatory Authority (NEPRA)",
                    link: "www.nepra.org.pk",
                  },
                  {
                    heading: "",
                    text: "NEPRA, an independent regulatory body, oversees and regulates the power sector to ensure transparency and efficiency",
                    link: "",
                  },
                  {
                    heading: "",
                    text: "For more details, visit www.nepra.org.pk",
                    link: "",
                  },
                ],
              },
              {
                heading: " Oil & Gas Sector",
                InnerList: [
                  {
                    heading: "",
                    text: "",
                    link: "",
                    subHeading: "",
                  },
                  {
                    heading: "Regulatory Authority",
                    text: "Oil & Gas Regulatory Authority (OGRA)",
                    link: "www.ogra.org.pk",
                  },
                  {
                    heading: "",
                    text: "OGRA, an independent regulatory body, governs the Oil & Gas sector, ensuring fair practices and fostering a conducive investment environment",
                    link: "",
                  },
                  {
                    heading: "",
                    text: "For more details, visit www.ogra.org.pk",
                    link: "",
                  },
                ],
              },
            ],
          },
          // ... (other tabs)
        ],
      },
    },
    {
      id: 5,
      name: "Industry, Tourism & Privatisation",
      content: {
        heading:
          "Establishment of Corporate Dairy Farm 30,000 Holstein Friesian Animals (15,000 Acres)",
        tagline:
          "Embark on a transformative venture with the establishment of a cutting-edge corporate dairy farm, housing 30,000 Holstein Friesian animals across an expansive 15,000-acre domain.",
        tabs: [
          {
            title: "Policies",
            list: [
              {
                heading: "",
                text: "",
                image: EVP,
                link: "http://www.engineeringpakistan.com/wp-content/uploads/2020/08/EV-New-Tech-Policy-060420.pdf",
              },
              {
                heading: "",
                text: "",
                image: MDP,
                link: "http://engineeringpakistan.com/wp-content/uploads/2020/09/Mobile-Device-Manufacturing-Policy.pdf",
              },
              {
                heading: "",
                text: "",
                image: ADP,
                link: "https://engineeringpakistan.com/wp-content/uploads/2020/08/ADP-2016latest.pdf",
              },
              {
                heading: "",
                text: "",
                image: TSP,
                link: "https://invest.gov.pk/tourism-and-hospitality?language_id=en#gallery",
              },
              {
                heading: "",
                text: "",
                image: evp,
                link: "https://pama.org.pk/wp-content/uploads/2022/04/AIDEP-2021-26.pdf",
              },
              // {
              //   heading: "",
              //   text: "",
              //   image: CDLT,
              //   link: "https://pama.org.pk/wp-content/uploads/2022/04/AIDEP-2021-26.pdf",
              // },
              {
                heading: "",
                text: "",
                image: Private,
                link: "https://Privatisation.gov.pk//SiteImage/Misc/files/Privatisation_Policy_1994.pdf",
              },
            ],
          },
          {
            title: "Incentives",
            list: [
              {
                heading: "Manufacturing Sector Incentives",
                InnerList: [
                  {
                    heading: "Government Permission",
                    text: " Not required, excluding specified industries",
                    link: "",
                  },
                  {
                    heading: "Capital Flow",
                    text: " Allowance for remittance of capital, profits, dividends, etc",
                    link: "",
                  },
                  {
                    heading: "Foreign Equity",
                    text: " Enjoy a 100% upper limit on foreign equity",
                    link: "",
                  },
                  {
                    heading: "Customs Duty on PME",
                    text: " Benefit from a minimal 5% customs duty on Plant, Machinery, and Equipment (PME)",
                    link: "",
                  },

                  {
                    heading: "Tax Relief on PME ",
                    text: "Enjoy a 25% tax relief (IDA, % of PME cost)",
                    link: "",
                    subHeading: "",
                  },
                  {
                    heading: "Royalty & Technical Fee ",
                    text: "No limitations on royalty and technical fee payments",
                    link: "",
                    subHeading: "",
                  },
                  {
                    heading:
                      "Incentives Under Auto Industry Development & Export Policy (AIDEP 2021-26) ",
                    text: "It emphasizes promoting compact vehicles and the adoption of new technologies with a targeted focus on entering export markets.",
                    link: "https://engineeringpakistan.com/wp-content/uploads/2021/12/AIDEP-Incentives-Final-221221-modified.pdf",
                  },
                ],
              },

              {
                heading:
                  "Non-Manufacturing Sector (Infrastructure & Social) Incentives",
                InnerList: [
                  {
                    heading: "Government Permission",
                    text: " Not required, with exceptions for specific licenses from concerned agencies",
                    link: "",
                  },
                  {
                    heading: "Capital Flow",
                    text: " Allowance for remittance of capital, profits, dividends, etc",
                    link: "",
                  },
                  {
                    heading: "Foreign Equity",
                    text: " Embrace a 100% upper limit on foreign equity",
                    link: "",
                  },
                  {
                    heading: "Customs Duty on PME",
                    text: " Enjoy a 0% customs duty on Plant, Machinery, and Equipment (PME)",
                    link: "",
                  },
                  {
                    heading: "Tax Relief on PME",
                    text: " Avail a 25% tax relief (IDA, % of PME cost)",
                    link: "",
                  },
                  {
                    heading: "Royalty & Technical Fee",
                    text: " Allowances per guidelines, including an initial lump sum up to $100,000, a maximum rate of 5% of net sales, and an initial period of 5 years",
                    link: "",
                  },
                ],
              },

              {
                heading: "Incentives under SEZ & EPZ",
                InnerList: [
                  {
                    heading: "Incentives under SEZ",
                    text: " Ten-year income tax exemption for Zone Developers, Codevelopers, and Zone Enterprises",
                    link: "https://invest.gov.pk/sez",
                  },
                  {
                    heading: "",
                    text: "  One-time exemption on custom duties and taxes on capital goods imports",
                    link: "",
                  },
                  {
                    heading: "Incentives under EPZ",
                    text: " Exemption from custom duties and taxes on machinery, equipment, and materials  ",
                    link: "https://epza.gov.pk/incentives/",
                  },
                  {
                    heading: "",
                    text: " Freedom from national import regulations and exchange control regulations   ",
                    link: "",
                  },
                  {
                    heading: "",
                    text: " No sales tax on inputs, with access to the domestic market up to 20%  ",
                    link: "",
                  },
                ],
              },

              {
                heading: "Tourism Sector Incentives",
                InnerList: [
                  {
                    heading: "Reduced Duty on Imports",
                    text: " Experience reduced duty on machinery, equipment, and project-related items for hotels around Gwadar's zero point and pre-fabricated structures in select scenic areas",
                    link: "",
                  },
                  {
                    heading: "Sales Tax Exemption in Gwadar",
                    text: " Enjoy a sales tax exemption on the import of capital goods for hotel setups in the Gwadar area",
                    link: "",
                  },
                  {
                    heading: "Loss Set-Off for Hotel Companies",
                    text: " Hotel-operating companies facing losses can offset the amount against their income in Pakistan",
                    link: "",
                  },
                  {
                    heading: "Tourist Visa on Arrival",
                    text: " Tourists from 50 countries can apply for a visa upon arrival",
                    link: "",
                  },
                  {
                    heading: "Business Visa on Arrival",
                    text: " Granting a 30-day Single-Entry Business Visa on Arrival to businessmen from 95 countries",
                    link: "",
                  },
                  {
                    heading: "Export Finance for Consultancy Services",
                    text: " Foreign earnings remitted for consultancy services in hotel and tourism qualify for export status, unlocking access to export finance",
                    link: "",
                  },
                  {
                    heading: "Income Tax Exemption for Hotel Services",
                    text: " Payments received by hotels for services are exempt from income tax deduction",
                    link: "",
                  },
                ],
              },
            ],
          },
          {
            title: "Regulations",
            list: [
              {
                heading: "",
                InnerList: [
                  {
                    heading: "Engineering Development Board (EDB)",
                    text: "Engineering Development Board (EDB) is mandated with policy formulation and implementation for facilitating and encouraging development & growth of the Engineering Industry of the country. For further details visit https://engineeringpakistan.com",
                    link: "https://engineeringpakistan.com",
                  },
                  {
                    heading: "Special Economic Zones Framework ",
                    text: "It encourages domestic and international investors for the promotion and establishment of industrial infrastructures focusing on export promotion, import substitution, transfer of technology, and employment generation",
                    link: "https://invest.gov.pk/sez",
                  },
                  {
                    heading: "Export Processing Zones Authority  ",
                    text: "It focuses on improving the exports of the country and accelerating the pace of industrialization by establishing investor-friendly zones for ambitious projects, fostering job creation and technology infusion",
                    link: "https://epza.gov.pk",
                  },
                ],
              },

              {
                heading:
                  "Provincial Special Economic Zones Regulatory Authorities",
                InnerList: [
                  {
                    heading: "Sindh Economic Zones Management Company ",
                    text: "It empowers investors to invest in manufacturing through tailored exemptions and incentives leading to a cost-effective business environment supported by efficient infrastructure",
                    link: "https://sezmc.gos.pk/wpsite/",
                  },

                  {
                    heading: "Punjab Board of Investment & Trade / FIEDMC",
                    text: "It focuses on promoting enhanced infrastructure and providing a platform to foster economic growth, create social cohesion, improve the environment, and deliver superior public services.",
                    link: "http://www.pbit.gop.pk/special-economic-zones",
                  },
                  {
                    heading:
                      "Khyber Pakhtunkhwa Economic Zones Development and Management Company (KPEZDMC) ",
                    text: "The company endeavors to create and oversee top-tier industrial estates in Khyber Pakhtunkhwa, fostering organized and swift industrialization",
                    link: "https://kpezdmc.org.pk/about-us#:~:text=Khyber%20Pakhtunkhwa%20Economic%20Zones%20Development,by%20government%20of%20Khyber%20Pakhtunkhwa.",
                  },
                  {
                    heading: "Bostan Special Economic Zone ",
                    text: "It serves as a catalyst for economic prosperity, with a wealth of skilled labor and adjacency to key areas known for agriculture and industrial activities.",
                    link: "https://cpec.gov.pk/project-details/55",
                  },
                ],
              },

              {
                heading: "Provincial Tourism Regulatory Authorities",
                InnerList: [
                  {
                    heading: "Sindh",
                    text: "Explore the rich offerings of Sindh at stdc.gos.pk",
                    link: "stdc.gos.pk",
                  },

                  {
                    heading: "Punjab",
                    text: "Explore the vibrant attractions of Punjab at tdcp.gop.pk",
                    link: "tdcp.gop.pk",
                  },
                  {
                    heading: "KP",
                    text: "Explore the picturesque landscapes and cultural treasures of KP at kptourism.com",
                    link: "kptourism.com",
                  },
                  {
                    heading: "Balochistan",
                    text: "Explore the natural beauty and cultural diversity of Balochistan by visiting balochistan.gov.pk",
                    link: "balochistan.gov.pk",
                  },
                  {
                    heading: "AJ&K",
                    text: "Experience the enchanting beauty and cultural heritage of AJ&K by visiting ajktourism.gov.pk",
                    link: "ajktourism.gov.pk",
                  },
                  {
                    heading: "Gilgit Baltistan",
                    text: "Explore the adventure and natural splendors of Gilgit Baltistan at visit gilgitbaltistan.gov.pk.",
                    link: "gilgitbaltistan.gov.pk",
                  },
                ],
              },

              {
                heading: "Privatization",
                InnerList: [
                  {
                    heading: "Legislation related to Privatization",
                    text: "It aims to enhance operational efficiency and safeguard consumer interests by promoting inclusive capital markets through strategic privatisation",
                    link: "https://Privatisation.gov.pk/Detail/ZGE3YWQyYzQtMDdiYy00MTMxLWI3MjYtN2Y5OTMyM2I5OGVl",
                  },
                ],
              },
            ],
          },
          // ... (other tabs)
        ],
      },
    },
  ];

  const [selectedProject, setSelectedProject] = useState(projects[0]);

  useEffect(() => {
    setSelectedProject(projects[paramID]);
    setActiveTab(paramTab);
  }, [paramID, paramTab]);

  const navbarStyle = {
    backgroundColor: scrolling ? "white" : "transparent", // Change color based on scrolling
    transition: "background-color 0.3s ease", // Add a smooth transition
    color: scrolling ? "#000" : "#fff",
  };
  const [data, setData] = useState([]);
  // const imaeglinkservrt = "https://staging.nitb.gov.pk:8620/";
  const truncateWords = (text, numWords) => {
    const words = text.split(" ");
    return (
      words.slice(0, numWords).join(" ") +
      (words.length > numWords ? " ..." : "")
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}all-events`);
        setData(response.data.data || []); // Ensure that response.data is an array or default to an empty array
        // console.log(response.data.data);
      } catch (error) {
        // console.log(error);
      }
    };

    fetchData();
  }, []);
  const settings = {
    arrows: false,
    draggable: false,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    // variableWidth: true,
    // variableHeight: true,
    responsive: [
      {
        breakpoint: 1540,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings1 = {
    arrows: false,

    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    // speed: 5000,
    // autoplaySpeed: 50,
    // cssEase: "linear",
    initialSlide: 0,
    pauseOnHover: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slider = React.useRef(null);
  return (
    <>
      <div className="bgPictureInvestmentClimate11">
        <div
          className="relative bg-cover bg-center h-[554px] overflow-hidden bg-white bgPictureInvestmentClimate11"
          style={{
            transition: "background-image 1s ease", // Smooth transition
          }}
        >
          {/* Navbar */}
          <div className="relative">
            <nav className="w-full  fixed top-0 z-50 " style={navbarStyle}>
              <NavBar />
            </nav>
          </div>
          <div className="absolute inset-y-0 left-0 text-black flex flex-col justify-center items-center w-full">
            <div className="container mx-auto mt-[60px]">
              <div className="contianer-width flex flex-col items-center p-10">
                <h1 className="aboutusHeading !text-center !text-4xl md:!text-6xl font-bold">
                  Investment Climate
                </h1>{" "}
                <br></br>
                <p className="leaderdesig !text-xl"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" pt-4 md:pt-36 pb-10 flex flex-col items-center justify-center">
        <div className="contianer-width flex flex-col items-center justify-center mb-4 md:mb-[28px]">
          <div className="whySIFC !text-center  md:!text-[45px] !text-[25px] mx-4 flex flex-row gap-4 items-center content-center">
            Recent Policy Initiatives Through SIFC{" "}
            <div className="flex flex-row gap-[30px] ">
              <span
                className="cursor-pointer"
                onClick={() => slider?.current?.slickPrev()}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.2594 17.8666L7.39326 10.0005L15.2594 2.13436C15.4914 1.89946 15.6211 1.58227 15.6201 1.25206C15.6191 0.921861 15.4875 0.605467 15.254 0.371976C15.0205 0.138485 14.7041 0.00686749 14.3739 0.00586514C14.0437 0.00486279 13.7265 0.134557 13.4916 0.366626L4.74162 9.11663C4.50721 9.35105 4.37551 9.66898 4.37551 10.0005C4.37551 10.332 4.50721 10.6499 4.74162 10.8844L13.4916 19.6344C13.7265 19.8664 14.0437 19.9961 14.3739 19.9951C14.7041 19.9941 15.0205 19.8625 15.254 19.629C15.4875 19.3955 15.6191 19.0791 15.6201 18.7489C15.6211 18.4187 15.4914 18.1015 15.2594 17.8666Z"
                    fill="#22574d"
                  />
                </svg>
              </span>{" "}
              <span
                className="cursor-pointer"
                onClick={() => slider?.current?.slickNext()}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.74065 17.8666L12.6067 10.0005L4.74065 2.13436C4.50858 1.89946 4.37889 1.58227 4.37989 1.25206C4.38089 0.921861 4.51251 0.605467 4.746 0.371976C4.97949 0.138485 5.29588 0.00686749 5.62609 0.00586514C5.95629 0.00486279 6.27348 0.134557 6.50838 0.366626L15.2584 9.11663C15.4928 9.35105 15.6245 9.66898 15.6245 10.0005C15.6245 10.332 15.4928 10.6499 15.2584 10.8844L6.50838 19.6344C6.27348 19.8664 5.95629 19.9961 5.62609 19.9951C5.29588 19.9941 4.97949 19.8625 4.746 19.629C4.51251 19.3955 4.38089 19.0791 4.37989 18.7489C4.37889 18.4187 4.50858 18.1015 4.74065 17.8666Z"
                    fill="#22574d"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="text-center Heading22 !font-normal">
            SIFC is committed to developing an enabling policy environment for
            seamless investments
          </div>
          <div className="flex flex-col justify-center w-full items-center">
            <div className="contianer-width md:px-8 mt-8">
              <div>
                <Slider ref={slider} {...settings}>
                  {Array.isArray(cards) &&
                    cards.map((events) => (
                      <>
                        <div class="rounded-lg bg-cover mx-2  bg-no-repeat">
                          <img className="h-96" src={events.image}></img>
                        </div>
                      </>
                    ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="policy"
        className="pb-[100px] flex flex-col items-center justify-center"
      >
        <div className="contianer-width flex flex-col items-center justify-center mb-6">
          <div className="whySIFC !text-center md:!text-[45px] !text-[25px]">
            Sectoral Investment Ecosystem
          </div>
        </div>
        <div className="flex container lg:justify-center mx-4 my-4 md:my-8 gap-4 overflow-x-auto">
          {projects.map((project) => (
            <div
              key={project.id}
              className={` !rounded-lg border  !text-center px-[20px] py-[16px] sidebar-item ${
                selectedProject.id === project.id
                  ? "sliderProjectsListHoverInvestment border-[#f08a23]  !text-[#056839] !font-medium"
                  : "sliderProjectsListInvestment !font-normal !text-[#073332]"
              }`}
              onClick={() => {
                // setSelectedProject(project);
                setparamID(project.id);
                // console.log(project, "project");
                // Reset the active tab when changing the selected project
                setActiveTab(0);
              }}
            >
              {project.name}
              {/* <div className="sidebar cursor-pointer flex flex-row items-center gap-[20px] justify-center px-[20px]">
                  <div> {project.name}</div>
                </div> */}
            </div>
          ))}
        </div>
        <div className="col-span-3 md:col-span-7 container ">
          <div className="content">
            <div className="text-base cursor-pointer flex justify-center flex-row">
              {selectedProject.content.tabs.map((tab, index) => (
                <p
                  key={index}
                  className={`text-base border  !no-underline tab px-4 md:px-8 ${
                    activeTab == index
                      ? "description-projectHover border-b-2 border-b-[#f08a23]"
                      : "description-project"
                  }`}
                  onClick={() => {
                    setActiveTab(index);
                    // console.log(index, "indexes");
                  }}
                >
                  {tab.title}
                </p>
              ))}
            </div>
            <div className="tab-content">
              {/* <h3 className="text-lg">
                  {selectedProject.content.tabs[activeTab].title}
                </h3> */}

              {selectedProject.content.tabs[activeTab].title === "Policies" && (
                <>
                  <ul className="text-base p-4 hidden md:flex flex-row flex-nowrap md:flex-wrap justify-center text-left  px-8">
                    {selectedProject.content.tabs[activeTab].list.map(
                      (item, index) => (
                        <div
                          className="py-[5px] flex my-3 flex-row gap-4 md:gap-[30px] mx-4"
                          key={index}
                        >
                          <a
                            href={item.link}
                            target="_blank"
                            className="headingaeraeText !text-[#22574D]"
                          >
                            {item.image && (
                              <img
                                className="w-[246px] h-[330px] shadow-xl shadow-slate-800"
                                src={item.image}
                                alt={item.heading}
                              />
                            )}
                            <div className="headingaeraeTextText">
                              {item.text}
                            </div>{" "}
                          </a>
                        </div>
                        // <li key={index}>{item.heading}</li>
                      )
                    )}
                  </ul>
                  <div className="md:hidden block">
                    <Slider {...settings1}>
                      {selectedProject.content.tabs[activeTab].list.map(
                        (item, index) => (
                          <div
                            className="py-[5px] flex my-3 mx-4 flex-row"
                            key={index}
                          >
                            <a
                              href={item.link}
                              target="_blank"
                              className="headingaeraeText !text-[#22574D]"
                            >
                              {item.image && (
                                <img
                                  className="w-[270px] h-[350px] shadow-xl shadow-slate-800"
                                  src={item.image}
                                  alt={item.heading}
                                />
                              )}
                              {item.heading}
                            </a>
                            <div className="headingaeraeTextText">
                              {item.text}
                            </div>{" "}
                          </div>
                        )
                      )}
                    </Slider>
                  </div>
                </>
              )}
              {selectedProject.content.tabs[activeTab].title ===
                "General Policies & Regulations" && (
                <>
                  <ul className="text-base p-4 hidden md:flex flex-row flex-wrap justify-center text-left  px-4 md:px-8">
                    {selectedProject.content.tabs[activeTab].list.map(
                      (item, index) => (
                        <div
                          className="py-[5px] flex my-3 flex-row gap-4 md:gap-[30px]"
                          key={index}
                        >
                          <a
                            href={item.link}
                            target="_blank"
                            className="headingaeraeText !text-[#22574D]"
                          >
                            {item.image && (
                              <img
                                className="w-[270px] h-[350px] shadow-xl shadow-slate-800"
                                src={item.image}
                                alt={item.heading}
                              />
                            )}
                            {item.heading}
                          </a>
                          <div className="headingaeraeTextText">
                            {item.text}
                          </div>{" "}
                        </div>
                      )
                    )}
                  </ul>
                  <div className="md:hidden block">
                    <Slider {...settings1}>
                      {selectedProject.content.tabs[activeTab].list.map(
                        (item, index) => (
                          <div
                            className="py-[5px] flex my-3 mx-4 flex-row"
                            key={index}
                          >
                            <a
                              href={item.link}
                              target="_blank"
                              className="headingaeraeText !text-[#22574D]"
                            >
                              {item.image && (
                                <img
                                  className="w-[270px] h-[350px] shadow-xl shadow-slate-800"
                                  src={item.image}
                                  alt={item.heading}
                                />
                              )}
                              {item.heading}
                            </a>
                            <div className="headingaeraeTextText">
                              {item.text}
                            </div>{" "}
                          </div>
                        )
                      )}
                    </Slider>
                  </div>
                </>
              )}
              <div className="">
                {selectedProject.content.tabs[activeTab].list.map(
                  (item, index) => (
                    <div className="  px-4 md:px-8 py-4" key={index}>
                      <div className=" text-left py-5 Crimson-Text text-[24px] font-bold text-xl Poppins">
                        <h2 className="text-[#22574D]">{item.heading}</h2>
                      </div>
                      {item.InnerList && (
                        <table className="w-full">
                          <tbody>
                            {item.InnerList.map(
                              (subInnerItem, subInnerIndex) => (
                                <tr key={subInnerIndex} className="border ">
                                  <td className="py-3 w-auto md:w-[350px] px-4 md:px-8 flex flex-row justify-between">
                                    <a
                                      href={subInnerItem.link}
                                      target="_blank"
                                      className="headingaeraeText"
                                    >
                                      {subInnerItem.image && (
                                        <img
                                          className="w-[270px] h-[350px]"
                                          src={subInnerItem.image}
                                          alt={subInnerItem.heading}
                                        />
                                      )}

                                      {subInnerItem.heading}
                                    </a>
                                    {/* {subInnerItem.subHeading && (
                                      <div className="Poppins text-lg flex flex-row items-start justify-start text-[#329244] font-bold">
                                        {subInnerItem.subHeading}
                                      </div>
                                    )} */}
                                    {subInnerItem.link !== "" && (
                                      <img
                                        className="w-[20px] cursor-pointer"
                                        src={linkIcon}
                                      ></img>
                                    )}
                                  </td>
                                  <td className="py-3 px-4 md:px-8 headingaeraeTextText ">
                                    {!subInnerItem.subHeading && (
                                      <div>{subInnerItem.text}</div>
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )
                )}{" "}
              </div>

              {/* {selectedProject.content.tabs[activeTab].title ===
                "Regulations" && (
                <table className="text-base p-4 border-collapse w-full">
                 
                  <tbody>
                    {selectedProject.content.tabs[activeTab].list.map(
                      (item, index) => (
                        <tr key={index} className="border-b">
                          <td className="py-3 px-8">
                            <a
                              href={item.link}
                              target="_blank"
                              className="headingaeraeText"
                            >
                              {item.image && (
                                <img
                                  className="w-[270px] h-[350px]"
                                  src={item.image}
                                  alt={item.heading}
                                />
                              )}
                              {item.heading}
                            </a>
                            {item.subHeading && (
                              <h2 className="Poppins text-xl flex flex-row items-start justify-start text-[#329244] font-bold ">
                                {" "}
                                {item.subHeading}{" "}
                              </h2>
                            )}{" "}
                          </td>
                          <td className="py-3 px-8 headingaeraeTextText">
                            {!item.subHeading && <div>{item.text}</div>}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              )} */}

              {selectedProject.content.tabs[activeTab].title !== "Policies" &&
                selectedProject.content.tabs[activeTab].title !==
                  "General Policies & Regulations" &&
                selectedProject.content.tabs[activeTab].title !==
                  "Incentives" &&
                selectedProject.content.tabs[activeTab].title !==
                  "Regulations" && (
                  <>
                    <ul className="text-base p-4 text-left border px-8">
                      {selectedProject.content.tabs[activeTab].list.map(
                        (item, index) => (
                          <div
                            className="py-[5px] flex flex-row gap-[30px]"
                            key={index}
                          >
                            <a
                              href={item.link}
                              target="_blank"
                              className="headingaeraeText !text-[#22574D]"
                            >
                              {item.image && (
                                <img
                                  className="w-[270px] h-[350px]"
                                  src={item.image}
                                  alt={item.heading}
                                />
                              )}
                              {item.heading}
                            </a>
                            <div className="headingaeraeTextText">
                              {item.text}
                            </div>{" "}
                          </div>
                          // <li key={index}>{item.heading}</li>
                        )
                      )}
                    </ul>
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
