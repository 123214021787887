import React, { useState, useEffect } from "react";
import NavBar from "../NavBar";
import "./Home.css";
import EventSlider from "./EventSlider";
import { Link } from "react-router-dom";
// import InvestmentOpportunitiesSection from "../subcomponents/investmentOpportunitiesSection";
// import InvestmentOpportunitiesmobile from "../subcomponents/InvestmentOpportunitiesmobile";
import WhyInvestinPakistan from "../subcomponents/whyInvestinPakistan";
import Footer from "../subcomponents/footer";
import NewsandUpdates from "../subcomponents/newsandUpdates";
import Events from "../subcomponents/events";
import Leadership from "../subcomponents/leadeship";
// import FlashHighlights from "../subcomponents/flashHighlisghts";
import SifcFaciliates from "../subcomponents/sifcFaciliates";
import Testimonial from "../subcomponents/testnomials";
import mapofPakistan from "../../assests/images/aGRICULTURE-MAO 1.png";
import mapofPakistan2 from "../../assests/images/IT-Map 1(1).png";
import mapofPakistan3 from "../../assests/images/mineralpng.png";
import mapofPakistan4 from "../../assests/images/Power-map 1.png";
import mapofPakistan5 from "../../assests/images/Industry-mao 1.png";
// import mapofPakistan2 from "../../assests/images/Frame 7584(1).png";
import HeroSectionsSector22 from "../subcomponents/HeroSectionsSector copy";
import WhyInvestMobile from "../subcomponents/WhyInvestMobile";
import ArrowLeft from "../../assests/Icons/Frame 34.png";
import ArrowRight from "../../assests/Icons/Frame 33.png";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import axios from "axios";
import HeroSectionsSector from "../subcomponents/HeroSectionsSector";
import AOS from "aos";
import "aos/dist/aos.css";
import Vistas from "../subcomponents/Vistas";
import PopupBG from "../../assests/images/popup-box1.jpg";
import logo1 from "../../assests/images/logo1.svg";

const apiUrl = process.env.REACT_APP_API_URL;
const myURLForImages = process.env.REACT_APP_STAGGING_URL_FOR_Images;
export const Home2 = () => {
  const [phone, setPhone] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Send Message");
  const [scrolling, setScrolling] = useState(false);
  const [banners, setbanners] = useState([
    {
      id: 1,
      heading: "Welcome to Pakistan",
      tagline:
        "Unveil the Potential of South Asia’s Premier Investment Frontier",
      image: "/storage/banners/lKjTz3kTISIvcftVNtvmEP92ioWK5NDFkARr2RBX.jpg",
      created_at: "2024-02-20T09:55:05.000000Z",
      updated_at: "2024-02-20T09:55:05.000000Z",
    },
  ]);
  // const [isPopupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // `${apiUrl}news/${id}`
        const response = await axios.get(`${apiUrl}get-banners`);
        // console.log(response.data, "bannersss");
        setbanners(response.data.reverse());
        // console.log(response.data);
      } catch (error) {
        // setError(error);
        // console.log(error);
      } finally {
        // setLoading(false);
        // console.log("asd");
      }
    };
    fetchData();
    // setPopupVisible(true);
  }, []);
  // const handleClose = () => {
  //   setPopupVisible(false);
  // };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const navbarStyle = {
    backgroundColor: scrolling ? "white" : "transparent", // Change color based on scrolling
    transition: "background-color 0.3s ease", // Add a smooth transition
    color: scrolling ? "#000" : "#fff",
  };
  const [selectedSectormap, setSelectedSectormap] =
    useState("Mines & Minerals");
  const handleSectorChangemap = (sector) => {
    setSelectedSectormap(sector);
  };

  useEffect(() => {
    AOS.init({ duration: 10000 });
  }, []);
  useEffect(() => init(), []);

  const [i, setI] = useState(0);

  function init() {
    // setInterval(() => {
    // setTimeout(() => {
    // setI((prevI) => (prevI === 0 ? banners.length - 1 : prevI - 1));
    // }, 6000);
    // }, 5000);
  }

  function handleNext() {
    setI((prevI) => (prevI === banners.length - 1 ? 0 : prevI + 1));
  }
  function handlePrevious() {
    setI((prevI) => (prevI === 0 ? banners.length - 1 : prevI - 1));
  }

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
    designation: "",
    organization: "",
    attachment: null,
  });
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, attachment: file });
  };
  const handleRemoveAttachment = () => {
    setFormData({ ...formData, attachment: null });
    document.getElementById("file-input").value = ""; // Clear file input value
  };
  const handleDesignationChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, designation: value });
  };
  const handleMessageChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, message: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handlePhoneChange = (phone) => {
    setFormData({ ...formData, phone });
  };
  const handleSubmit = async () => {
    const {
      firstName,
      lastName,
      phone,
      email,
      message,
      organization,
      designation,
      attachment,
    } = formData;
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.phone ||
      !formData.email ||
      !formData.message ||
      !formData.designation ||
      !formData.organization
    ) {
      alert("Please fill in all the required fields.");
      return;
    }

    const data = new FormData();
    data.append("first_name", firstName);
    data.append("last_name", lastName);
    data.append("phone", phone);
    data.append("email", email);
    data.append("organization", organization);
    data.append("proposal", message);
    data.append("designation", designation);
    data.append("message", "designation");
    data.append("attachment", attachment);
    // api/submit-contact-form
    // console.log("FormData", data);
    try {
      // Assuming you have an API endpoint like "https://example.com/api/submit-contact-form"
      // const apiUrl = `https://staging.nitb.gov.pk:8620/api/submit-contact-form`;
      const response = await axios.post(`${apiUrl}submit-contact-form`, data);

      // Handle the success case
      // console.log("Success:", response);

      // Optionally, you can show a success prompt here
      window.alert("Form submitted successfully!");

      setIsDisabled(true);
      setButtonText("Sent");

      // Re-enable the button after 5 seconds
      setTimeout(() => {
        setIsDisabled(false);
        setButtonText("Send Message");
      }, 5000);
    } catch (error) {
      window.alert("Form submitted successfully!");
      setIsDisabled(true);
      setButtonText("Sent");

      // Re-enable the button after 5 seconds
      setTimeout(() => {
        setIsDisabled(false);
        setButtonText("Send Message");
      }, 5000);
      // Handle different error cases
      // if (error.response) {
      //   // The request was made and the server responded with a status code
      //   // that falls out of the range of 2xx
      //   // console.error("Server Error:", error.response.data);
      //   window.alert("Server Error. Please try again later.");
      // } else if (error.request) {
      //   // The request was made but no response was received
      //   // console.error("Request Error:", error.request);
      //   window.alert("No response from the server. Please try again later.");
      // } else {
      //   // Something happened in setting up the request that triggered an Error
      //   // console.error("General Error:", error.message);
      //   window.alert("An error occurred. Please try again later.");
      // }
    }
  };

  return (
    <div>
      <div>
        <div
          className="relative bg-cover bg-center h-screen overflow-hidden bgPicture22 "
          style={{
            transition: "background-image 2s ease", // Smooth transition

            backgroundImage:
              banners.length > 0 && banners[i]
                ? `url(${myURLForImages + banners[i]?.image})`
                : "",
          }}
        >
          {/* Navbar */}
          <div className="relative">
            <nav
              className="w-full text-white fixed top-0 !z-50"
              style={navbarStyle}
            >
              <NavBar />
            </nav>
          </div>
          <div className="text-black flex flex-col justify-center content-center items-center h-full w-full">
            <div className="container gap-8 mx-auto flex flex-col justify-center content-center h-full">
              <div className="flex items-center p-1">
                {" "}
                <div className="block h-8 md:h-auto ">
                  {" "}
                  <button
                    className="hover:bg-[#073332] rounded-full"
                    onClick={handlePrevious}
                  >
                    <img src={ArrowLeft} alt="Previous" />
                  </button>
                </div>
                <div className="contianer-width flex flex-col content-center justify-center items-center">
                  <h1
                    className="heroHeading !leading-10 md:!leading-normal text-[40px] md:text-[50px]  font-bold"
                    style={{
                      transition: "background-image 1s ease", // Smooth transition
                    }}
                  >
                    {banners[i]?.heading}
                    {/* {banners[0].hero} */}
                  </h1>{" "}
                  <p className="herotagline !leading-5 md:!leading-normal mx-4 text-base  md:text-[25px] ">
                    {banners[i]?.tagline}
                    {/* {banners[0].tagline} */}
                  </p>
                </div>
                <div className="block h-8 md:h-auto">
                  <button
                    className="hover:bg-[#073332] rounded-full"
                    onClick={handleNext}
                  >
                    <img src={ArrowRight} alt="Next" />
                  </button>
                </div>
              </div>

              <div className="flex md:hidden justify-center">
                <div className="w-11/12 h-full">
                  <HeroSectionsSector />
                </div>
              </div>
              {/* <div className="md:block hidden text-white absolute md:bottom-20 right-4">
                <div className="flex  flex-row gap-[25px]">
                  <button
                    className="bg-white p-1 rounded"
                    onClick={handlePrevious}
                  >
                    <img src={ArrowLeft} alt="Previous" />
                  </button>
                  <button className="bg-white p-1 rounded" onClick={handleNext}>
                    <img src={ArrowRight} alt="Next" />
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="hidden md:block absolute w-full bottom-28 lg:bottom-[-50px]">
          <HeroSectionsSector22 />
        </div>
      </div>

      {/* <div>
        <div className="block md:hidden">
          {" "}
          <InvestmentOppoSSrtunitiesmobile />
        </div>
        <div className="hidden md:block">
          {" "}
          <InvestmentOpportunitiesSection />
        </div>
      </div> */}

      <div className="pt-8 md:pt-[200px] pb-4 md:pb-[50px]">
        {/* <div className="block md:hidden">
          {" "}
          <WhyInvestMobile />
        </div> */}
        {/* <div className="hidden md:block"> */} <WhyInvestinPakistan />
        {/* </div> */}
      </div>
      <Vistas />

      <SifcFaciliates />

      <EventSlider />

      <Testimonial />

      <Leadership />

      <Events />

      <NewsandUpdates />

      <div className="py-8 md:py-20 flex flex-row w-full flex-wrap justify-center item-center">
        <div className="container flex flex-col px-2 md:flex-row w-full justify-center gap-4 items-center">
          <div
            data-aos="fade-right"
            className="xl:block hidden mt-[40px] p-4 relative"
          >
            <div className="absolute z-10 top-[-28px] right-[421px]">
              <div className="w-[258px]  flex-col justify-start items-start text-left">
                {[
                  "Agriculture",
                  "IT & Telecom",
                  "Mines & Minerals",
                  "Energy (Petroleum & Power)",
                  "Industry, Tourism & Privatization",
                ].map((sector) => (
                  <div
                    key={sector}
                    className={`cursor-pointer justify-start items-start  ${
                      selectedSectormap === sector ? "font-bold" : ""
                    }`}
                    onClick={() => handleSectorChangemap(sector)}
                  >
                    <div
                      className={`text-${
                        selectedSectormap === sector ? "#B4814E" : "#B0B0B0"
                      } text-base font-medium Poppins leading-[41.92px]`}
                    >
                      {sector}
                    </div>
                    {selectedSectormap === sector && (
                      <div className="border-t-2 border-solid border-[#B4814E] w-full mt-1" />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div>
              {selectedSectormap === "Mines & Minerals" && (
                <img src={mapofPakistan3} alt="Mines & Minerals Map" />
              )}
              {selectedSectormap === "Agriculture" && (
                <img src={mapofPakistan} alt="Agriculture Map" />
              )}
              {selectedSectormap === "Energy (Petroleum & Power)" && (
                <img src={mapofPakistan4} alt="Energy (Petroleum & Power)" />
              )}
              {selectedSectormap === "IT & Telecom" && (
                <img src={mapofPakistan2} alt="IT & Telecom" />
              )}
              {selectedSectormap === "Industry, Tourism & Privatization" && (
                <img
                  src={mapofPakistan5}
                  alt="Industry, Tourism & Privatization"
                />
              )}
            </div>
          </div>
          <div className="mx-4 p-4 md:p-10 bg-emerald-300 bg-opacity-20">
            <div className="AreYouready !text-[20px] md:!text-[24px] my-3">
              Are you ready to invest with us?
            </div>
            <div className="flex-col justify-center items-center flex gap-4">
              <div className="flex flex-wrap lg:flex-nowrap w-full justify-between gap-4">
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="border-b w-full py-3 h-[50px] rounded px-3 inputtext"
                  placeholder="First Name*"
                />
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="border-b w-full py-3 px-3 h-[50px] rounded inputtext"
                  placeholder="Last Name*"
                />
              </div>
              <div className="homepage w-full">
                <PhoneInput
                  className="w-full py-3"
                  defaultCountry="pk"
                  value={formData.phone}
                  onChange={handlePhoneChange}
                />
              </div>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="border-b w-full py-3 px-3 h-[50px] rounded inputtext"
                placeholder="Email*"
              />
              <input
                type="text"
                name="organization"
                value={formData.organization}
                onChange={handleChange}
                className="border-b w-full py-3 px-3 h-[50px] rounded inputtext"
                placeholder="Company/Organization"
              />
              <input
                type="text"
                name="designation"
                value={formData.designation}
                onChange={handleDesignationChange}
                className="border-b w-full py-3 px-3 h-[50px] rounded inputtext"
                placeholder="Designation"
              />

              <textarea
                name="message"
                value={formData.message}
                onChange={handleMessageChange}
                rows="3"
                className="border-b w-full py-3 px-3 rounded inputtext"
                placeholder="Proposal*"
              />

              <label className="attachfile relative w-full cursor-pointer Poppins py-3 px-3 h-[50px]">
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="hidden"
                  id="file-input"
                />
                <div className="file-input-label">
                  {formData.attachment ? (
                    <>
                      <span>{formData.attachment.name}</span>
                      <span
                        className="absolute remove-attachment  right-[-6px] top-[-10px]"
                        onClick={handleRemoveAttachment}
                      >
                        &#10005;
                      </span>
                    </>
                  ) : (
                    <span className="flex flex-row gap-3 items-center">
                      {" "}
                      Attachment{" "}
                      <svg
                        width="11"
                        height="13"
                        viewBox="0 0 11 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.84495 2.13417L8.15791 0.476669C7.85212 0.167915 7.4495 0 7.02646 0H2.29163C1.3742 0 0.625 0.796234 0.625 1.77126V11.2287C0.625 12.2037 1.3742 13 2.29163 13H8.70837C9.62577 13 10.375 12.2037 10.375 11.2287V3.43415C10.375 2.94125 10.1813 2.47 9.84495 2.13417ZM3.83592 4.45248C5.2138 2.9881 5.16598 3.03168 5.21714 2.9954C5.29867 2.93582 5.40061 2.90333 5.49745 2.90333H5.50255C5.63507 2.90333 5.76249 2.9629 5.85933 3.06043C6.3905 3.62714 7.21749 4.50947 7.16408 4.45248C7.36283 4.66374 7.36283 5.00499 7.16408 5.21625C6.9653 5.42751 6.64421 5.42751 6.44543 5.21625L6.01222 4.75582V6.66792C6.01222 6.97125 5.78286 7.20958 5.50255 7.20958C5.21714 7.20958 4.99288 6.97125 4.99288 6.66792V4.75582L4.55457 5.22167C4.36385 5.42437 4.03865 5.43713 3.83592 5.22167C3.63714 5.01042 3.63714 4.66374 3.83592 4.45248ZM7.47497 10.0967H3.52503C3.24471 10.0967 3.01536 9.85292 3.01536 9.55501C3.01536 9.25706 3.24471 9.01334 3.52503 9.01334H7.47497C7.75529 9.01334 7.98464 9.25706 7.98464 9.55501C7.98464 9.85292 7.75529 10.0967 7.47497 10.0967Z"
                          fill="#999999"
                        />
                      </svg>
                    </span>
                  )}
                </div>
              </label>

              <button
                onClick={!isDisabled ? handleSubmit : null}
                className={`Submitt w-full py-3  px-3 h-[50px] ${
                  isDisabled ? "cursor-progress  !bg-red-700" : ""
                }`}
                style={{ pointerEvents: isDisabled ? "none " : "auto" }}
              >
                {buttonText}
              </button>
              <div>or</div>
              <div className="text-center text-base Poppins pb-2">
                Connect with us at &#160;
                <a className="font-bold" href="mailto:invest@sifc.gov.pk">
                  invest@sifc.gov.pk
                </a>
                {/* <span>invest@sifc.gov.pk</span> */}
              </div>
              {/* <div className="attachfile w-full cursor-pointer Poppins py-3 px-3 h-[50px]">
                Attachment
              </div>
              <div className="Submitt w-full py-3 px-3 h-[50px]">
                Send Message{" "}
              </div> */}
            </div>
          </div>{" "}
          {/* Content for the second column */}
        </div>
      </div>

      {/* <Chatbot /> */}
      <Footer />
    </div>
  );
};
// {isPopupVisible && (
//   <div className="fixed  inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//     <div className="relative flex flex-col items-center rounded-lg shadow-lg  max-w-4xl md:w-3/5">
//       <img src={PopupBG} />
//       <button
//         onClick={handleClose}
//         className="text-green-700 absolute right-4 top-4 z-50 rounded hover:text-green-600"
//       >
//         <svg
//           class="w-3 h-3"
//           aria-hidden="true"
//           xmlns="http://www.w3.org/2000/svg"
//           fill="none"
//           viewBox="0 0 14 14"
//         >
//           <path
//             stroke="currentColor"
//             stroke-linecap="round"
//             stroke-linejoin="round"
//             stroke-width="2"
//             d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
//           />
//         </svg>
//       </button>
//       <div className="absolute text-center  flex flex-col items-center mt-2 md:mt-8 w-full h-full">
//         <img src={logo1} alt="SIFC Logo" className="h-20 xl:h-auto" />
//         <p className="text-green-900 text-normal md:text-xl xl:text-3xl my-1 md:my-2 ">
//           JOIN OUR TEAM AT SIFC{" "}
//         </p>
//         <p className="text-green-900 text-sm md:text-xl  ">
//           Exciting Career Opportunities Await!{" "}
//         </p>
//         <a
//           target="_blank"
//           href="/complaints"
//           className=" absolute top-1/2  right-4 transform -translate-y-1/2"
//         >
//           {" "}
//           <button
//             className="right-2 md:right-4   hover:bg-[#073332] rounded-full "
//             // className="bg-[#073332] p-1 rounded-full"                    &rarr;
//           >
//             <img className="h-6 md:h-auto" src={ArrowRight} alt="Next" />
//           </button>
//           <p className="text-green-900 text-sm md:text-normal font-bold">
//             {" "}
//             For Complaints
//           </p>
//         </a>
//         <p className="text-green-900 my-1 md:my-4 text-sm">
//           {" "}
//           {/* Note: For any complaints, please contact us at:{" "}
//           <span className="text-black">hr.pmu@sifc.gov.pk</span> */}
//         </p>
//         <p className="text-green-900 text-sm md:text-xl ">
//           To Explore Careers & Apply
//         </p>{" "}
//         <a
//           href="https://jobs.sifc.gov.pk/"
//           target="_blank"
//           rel="noopener noreferrer"
//           className="bg-green-800 mt-3 text-white px-4 py-2 rounded hover:bg-green-600"
//         >
//           {" "}
//           CLICK HERE
//         </a>
//       </div>
//     </div>
//   </div>
// )}
