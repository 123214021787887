import "./App.css";
// import NavBar from "./components/NavBar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home } from "./components/Pages/Home";
import { useEffect, useState } from "react";
import { About } from "./components/Pages/About";
import { Contact } from "./components/Pages/Contact";
import SelectSectors from "./components/Pages/Sectors";
import AgricultureMainPage from "./components/Pages/AgricultureMainPage";
// import ReactDOM from "react-dom";
// import NewsandUpdates from "./components/subcomponents/newsandUpdates";
import Newsandevents from "./components/subcomponents/newsandevents";
// import Leadership from "./components/subcomponents/leadeship";
import Leadership_org from "./components/subcomponents/Leadership_org";
// import EventPage1 from "./components/subcomponents/EventDetailPages/EventPage1";
// import EventPage2 from "./components/subcomponents/EventDetailPages/EventPage2";
// import EventPage3 from "./components/subcomponents/EventDetailPages/EventPage3";
// import EventsPage4 from "./components/subcomponents/EventDetailPages/EventsPage4";
// import EventsPage5 from "./components/subcomponents/EventDetailPages/EventsPage5";
// import NewsDetailsPage1 from "./components/subcomponents/NewsDetailPages/NewsDetailpage1";
// import NewsDetailsPage2 from "./components/subcomponents/NewsDetailPages/NewsDetailpage2";
// import NewsDetailsPage3 from "./components/subcomponents/NewsDetailPages/NewsDetailpage3";
// import NewsDetailsPage4 from "./components/subcomponents/NewsDetailPages/NewsDetailpage4";
// import NewsDetailsPage5 from "./components/subcomponents/NewsDetailPages/NewsDetailpage5";
import SuccessStories from "./components/Pages/SuccessStories";
import SuccessStoriesDetail from "./components/Pages/SuccessStoriesDetail";
import Partners from "./components/Pages/Partners";
import SifcVisa from "./components/Pages/SifcVisa";
import EnergyMainPage from "./components/Pages/EnergyMainPage";
import IndustryMainPage from "./components/Pages/IndustryMainPage";
import ITmainPage from "./components/Pages/ITmainPage";
import MineralsMainPage from "./components/Pages/MineralsMainPage";
// import Chatbot from "./components/Pages/Chatbot";
import FAQs from "./components/Pages/FAQs";
import WhyInvest from "./components/Pages/WhyInvest";
import CoreValues from "./components/Pages/CoreValues";
import InvestmentClimate from "./components/Pages/InvestmentClimate";
import Insights from "./components/Pages/Insights";
import ScrollToTop from "./components/ScrollToTop";
import ScrollToAnchor from "./components/ScrollToAnchor";
import EventPage1 from "./components/subcomponents/DetailPages/EventDetailPage";
import NewsPage from "./components/subcomponents/DetailPages/NewsDetailPage";
import PressReleasedetails from "./components/subcomponents/DetailPages/PressReleasedetails";
import Establishbusinessinpakistan from "./components/Pages/Establish-business-in-pakistan";
import { Home2 } from "./components/Pages/Home2";
import HeroSectionsSector22 from "./components/subcomponents/HeroSectionsSector copy";
import Tenders from "./components/Pages/Tenders";
import ReactGA from "react-ga";
import Complaints from "./components/Pages/Complaints";
const TRACKING_ID = "G-FCXFCHRPJ7"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
function App() {
  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
      <Router>
        <ScrollToTop />
        <ScrollToAnchor />
        <div className="pages">
          <Routes>
            {" "}
            <Route path="/" element={<Home2 />} />
            <Route path="/#why_invest" element={<Home2 />} />
            <Route path="/#success_stories" element={<Home2 />} />
            <Route path="/#our_facilitation_spectrum" element={<Home2 />} />
            {/* <Route path="/Home2" element={<Home />} /> */}
            <Route
              path="/HeroSectionsSector2"
              element={<HeroSectionsSector22 />}
            />
            <Route path="/about" element={<About />} />
            <Route path="/sectors" element={<SelectSectors />} />
            <Route
              path="/agricuture-sector"
              element={<AgricultureMainPage />}
            />{" "}
            <Route path="/tenders" element={<Tenders />} />{" "}
            <Route path="/IndustryMainPage" element={<IndustryMainPage />} />
            <Route path="/EnergyMainPage" element={<EnergyMainPage />} />
            <Route path="/Partners" element={<Partners />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/SifcVisa" element={<SifcVisa />} />
            <Route path="/newsandevents" element={<Newsandevents />} />
            <Route path="/ITmainPage" element={<ITmainPage />} />
            <Route path="/MineralsMainPage" element={<MineralsMainPage />} />
            <Route path="/FAQs" element={<FAQs />} />
            <Route
              path="/leadership_organization"
              element={<Leadership_org />}
            />
            <Route path="/success_stories" element={<SuccessStories />} />
            <Route
              path="/Establishbusinessinpakistan"
              element={<Establishbusinessinpakistan />}
            />
            <Route
              path="/success_stories/details/:id"
              element={<SuccessStoriesDetail />}
            />
            <Route path="/event/:id" element={<EventPage1 />} />
            <Route path="/news/:id" element={<NewsPage />} />
            <Route
              path="/press-release/:id"
              element={<PressReleasedetails />}
            />
            <Route path="/why_invest_in_pakistan" element={<WhyInvest />} />
            <Route path="/core_values" element={<CoreValues />} />
            <Route path="/investment_climate" element={<InvestmentClimate />} />
            <Route path="/insights" element={<Insights />} />
            <Route path="/complaints" element={<Complaints />} />
          </Routes>
          {/* <Chatbot /> */}
        </div>
      </Router>
    </>
  );
}

export default App;
