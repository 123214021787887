import React from "react";
import PopupBG from "../../assests/images/popup-box1.jpg";
import logo1 from "../../assests/images/logo1.svg";

export default function Complaints() {
  return (
    <div className=" flex flex-col items-center rounded-lg shadow-lg">
      <div className=" text-center  flex flex-col items-center mt-2 md:mt-8 w-full h-full">
        <img src={logo1} alt="SIFC Logo" className="h-20 xl:h-auto" />
        <p className="text-green-900 text-normal md:text-xl xl:text-3xl my-1 md:my-2 ">
          JOIN OUR TEAM AT SIFC{" "}
        </p>
        <p className="text-green-900 text-sm md:text-xl  ">
          Exciting Career Opportunities Await!{" "}
        </p>
        <p className="text-green-900 my-1 md:my-4 text-sm">
          {" "}
          {/* Note: For any complaints, please contact us at:{" "}
        <span className="text-black">hr.pmu@sifc.gov.pk</span> */}
        </p>
        <p className="text-green-900 text-sm md:text-xl font-bold">
          Can't access the main job portal? No worries!
        </p>
        <br />
        <p className="text-green-900 text-sm md:text-xl ">
          Submit your application via email at{" "}
          <span className="text- underline">hr.pmu@sifc.gov.pk</span>.
          <br />
          Kindly include your job title along with all required documents.{" "}
        </p>{" "}
      </div>
    </div>
  );
}
